<template>
  <a-modal
    title="修改轮巡方案"
    width="32%"
    :visible="show"
    :confirm-loading="confirmLoading"
    :centered="true"
    destroyOnClose
    @ok="handleOk"
    @cancel="$emit('close')"
  >
    <div class="flex items-center m-b-sm">
      <span class="wd-name">方案名称：</span>
      <a-input
        v-model="propData.cameraPatrolName"
        class="flex-one"
        placeholder="请输入方案名称"
      />
    </div>

    <div class="flex items-center">
      <span class="wd-name">轮巡描述：</span>
      <div class="flex-one">
        <a-textarea
          placeholder="请输入轮巡描述"
          v-model="propData.cameraPatrolDescribe"
          :rows="4"
          :maxLength="200"
        />
      </div>
    </div>
    <span class="tips text-right text-red block">200个字</span>
  </a-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    propData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      backups: {
        cameraPatrolName: '',
      },
      confirmLoading: false,
    };
  },
  watch: {
    show(v) {
      if (v) {
        this.backups.cameraPatrolName = this.propData.cameraPatrolName;
      } else {
        this.confirmLoading = false;
      }
    },
  },
  methods: {
    handleOk() {
      this.confirmLoading = true;
      this.propData.cameraPatrolName =
        this.propData.cameraPatrolName || this.backups.cameraPatrolName;
      const params = {
        id: this.propData.id,
        params: this.propData,
      };
      this.$store.dispatch('modifyCameraPatrol', params).then(data => {
        if (data.code === 200) {
          this.$message.success('修改轮巡预案成功');
          this.$emit('update');
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
