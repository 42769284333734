<template>
  <a-modal
    title="轮巡结果"
    :visible="visible"
    width="94%"
    :bodyStyle="bodyStyle"
    :centered="true"
    :footer="null"
    :destroyOnClose="true"
    @cancel="handleCancel"
  >
    <div class="content full-height">
      <div class="flex justify-between items-center m-b-sm">
        <div class="flex-one items-center text-lightblue">
          <span class="flex-s m-r-xs ">状态：</span>
          <a-select
            v-model="status"
            class="m-r-sm search-mx-wd-1"
            placeholder="请选择摄像机状态"
          >
            <a-select-option
              v-for="item in statusArr"
              :value="item.value"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <span class="flex-s m-r-xs">管辖单位：</span>
          <a-cascader
            :options="organizationListFormat"
            :display-render="displayRender"
            v-model="organizationId"
            expand-trigger="hover"
            placeholder="管辖单位"
            change-on-select
            @change="selectOrganization"
            class="m-r-sm  search-mx-wd-1"
          />
          <a-button
            type="primary"
            class="m-r-sm btn-search"
            @click="searchHandle"
          >
            搜索
          </a-button>
          <a-button class="btn-reset m-r-sm" @click="resetHandle">
            重置
          </a-button>
          <a-button class="btn-export m-r-sm" @click="exportHandle">
            导出
          </a-button>
        </div>
        <div>
          耗时：{{ statistic.duration }} 轮巡摄像机：{{
            statistic.cameraNumber
          }}
          正常：{{ statistic.normalNumber }} 异常：{{
            statistic.abnormalNumber
          }}
          离线：{{ statistic.offlineNumber }} 故障：{{ statistic.faultNumber }}
        </div>
      </div>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => index"
        :components="resibleTableHeader"
        :data-source="data"
        :pagination="paginationOpt"
        bordered
        :scroll="scroll"
        :loading="loading"
      >
        <template slot="classify" slot-scope="text">
          {{ text | getClassify }}
        </template>
        <template slot="derection" slot-scope="text">
          {{ text | getDirection }}
        </template>
        <template slot="cameraStatus" slot-scope="text">
          {{ text | getCameraStatusNew }}
        </template>
      </a-table>

      <div class="text-center m-t-sm">
        <a-button type="primary" @click="handleCancel">确定</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CODE_OK } from '@/request/config_code';
import ResizableTable from '@/components/mixins/resizeableTable';
import { handleExportFile } from '@/tools';
export default {
  name: 'TurnResultDialog',
  mixins:[ResizableTable],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      bodyStyle: { height: `${window.innerHeight - 80}px`,x:'100%' },
      status: undefined,
      statusArr: [
        {
          id: 0,
          value: 1,
          name: '正常',
        },
        {
          id: 1,
          value: -1,
          name: '异常',
        },
        {
          id: 2,
          value: 0,
          name: '离线',
        },
        {
          id: 3,
          value: 2,
          name: '故障',
        },
      ],
      organizationCurrentId: '',
      organizationId: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'resultIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
          ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationName',
          width: 100,
          ellipsis: true,
        },
        {
          title: '路线',
          dataIndex: 'roadName',
          width: 200,
          ellipsis: true,
        },
        {
          title: '桩号',
          dataIndex: 'kmHmPile',
          width: 100,
          ellipsis: true,
        },
        {
          title: '兴趣点名称',
          dataIndex: 'poiName',
          width: 200,
          ellipsis: true,
        },
        {
          title: '所属类别',
          dataIndex: 'classify',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'classify' },
        },
        {
          title: '方向',
          dataIndex: 'derection',
          width: 80,
          ellipsis: true,
          scopedSlots: { customRender: 'derection' },
        },
        {
          title: '状态',
          dataIndex: 'cameraStatus',
          width: 80,
          ellipsis: true,
          scopedSlots: { customRender: 'cameraStatus' },
        },
      ],
      data: [],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getPatrolResultInfo();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getPatrolResultInfo();
        },
      },
      statistic: {},
      scroll: { y: window.innerHeight - 400 },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      organizationList: (state) => state.collect.organizationList,
    }),
    organizationListFormat() {
      return this.organizationList.map((item) => {
        return item;
      });
    },
  },
  watch: {
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this.paginationOpt.current = 1;
        this.paginationOpt.defaultPageSize = 10;
        this._getResultStatistic();
        this._getPatrolResultInfo();
      }
    },
  },
  created() {
    this.getOrganationList();
  },
  methods: {
    ...mapActions(['getOrganationList']),
    //导出
    exportHandle(){
      let data = {
        cameraPatrolId:this.id,
        params:{
          organizationId:this.organizationCurrentId,
          cameraStatus:this.status
        }
      }
      this.$api.cameraPatrol.exportResult(data).then((res) => {
        this.$message.success('导出轮巡结果列表成功');
        handleExportFile(res.data, '轮巡结果列表.xls');
      })
    },
    getStyle() {
      return { height: `${document.documentElement.clientHeight - 200}px` };
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectOrganization(value) {
      this.organizationId = value;
      this.organizationCurrentId =
        this.organizationId.length > 0
          ? this.organizationId[this.organizationId.length - 1]
          : '';
    },
    _getResultStatistic() {
      this.statistic = {};
      this.$api.cameraPatrol.getResultStatistic(this.id).then((res) => {
        if (res.code === CODE_OK) {
          this.$set(
            this.statistic,
            'duration',
            res.data ? res.data.duration : ''
          );
          this.$set(
            this.statistic,
            'cameraNumber',
            res.data ? res.data.cameraNumber : ''
          );
          this.$set(
            this.statistic,
            'normalNumber',
            res.data ? res.data.normalNumber : ''
          );
          this.$set(
            this.statistic,
            'abnormalNumber',
            res.data ? res.data.abnormalNumber : ''
          );
          this.$set(
            this.statistic,
            'offlineNumber',
            res.data ? res.data.offlineNumber : ''
          );
          this.$set(
            this.statistic,
            'faultNumber',
            res.data ? res.data.faultNumber : ''
          );
        }
      });
    },
    _getPatrolResultInfo() {
      let newParams = {
        id: this.id,
        params: {
          organizationId: this.organizationCurrentId,
          cameraStatus: this.status,
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
        },
      };
      this.loading = true;
      this.$api.cameraPatrol.getPatrolResultInfo(newParams).then((res) => {
        if (res.code === CODE_OK) {
          this.loading = false;
          this.data = res.data;
          this.paginationOpt.total = res.total;
        }
      });
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getPatrolResultInfo();
    },
    resetHandle() {
      this.organizationId = [];
      this.organizationCurrentId = '';
      this.status = undefined;
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.data = [];
      this.searchHandle();
    },
    handleCancel() {
      this.resetHandle();
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
