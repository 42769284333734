<template>
  <div class="full-width full-height bg-blue p-sm">
    <back />
    <search-bar
      class="m-t-sm m-b-sm"
      @search="searchHandle"
    />

    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        onSelectAll: onSelectAllHandle
      }"
      :components="resibleTableHeader"
      :columns="columns"
      :rowKey="(record, index) => index"
      :data-source="turnFormatList"
      :pagination="paginationOpt"
      :scroll="scroll"
      :loading="loading"
      bordered
    >
      <template slot="action" slot-scope="text, record">
        <!--     1. v-if="record.cameraPatrolPlanStatus !== '1'" :class="{
                'plain-btn-disable': record.cameraPatrolPlanStatus === '2',
              }"
              :disabled="record.cameraPatrolPlanStatus === '2'" -->
        <a-tooltip
          placement="top"
          v-if="record.cameraPatrolPlanStatus === '0'"
        >
          <template slot="title">
            <span>提前执行</span>
          </template>
          <button
            class="plain-btn pointer m-r-sm"
            @click="playHandle(record)"
          >
            <a-icon type="play-circle" />
          </button>
        </a-tooltip>
        <a-tooltip
          placement="top"
          v-if="record.cameraPatrolPlanStatus === '1'"
        >
          <template slot="title">
            <span>提前停止</span>
          </template>
          <button
            class="plain-btn pointer m-r-sm"
            @click="pauseHandle(record)"
          >
            <a-icon type="pause" />
          </button>
        </a-tooltip>
        <!--:class="{
              'plain-btn-disable':
                (record.cameraPatrolPlanStatus === '2' && !$g.treeDefinate) ||
                record.cameraPatrolPlanStatus === '1',
            }"
            :disabled="
              (record.cameraPatrolPlanStatus === '2' && !$g.treeDefinate) ||
                record.cameraPatrolPlanStatus === '1'
            "-->
        <a-tooltip placement="top">
          <template slot="title">
            <span>修改</span>
          </template>
          <button
            class="plain-btn pointer m-r-sm"
            @click="editHandle(record)"
          >
            <a-icon type="edit" />
          </button>
        </a-tooltip>
        <a-tooltip
          placement="top"
          v-if="record.cameraPatrolPlanStatus === '2'"
        >
          <template slot="title">
            <span>查看结果</span>
          </template>
          <button
            class="plain-btn pointer m-r-sm"
            @click="viewResult(record)"
          >
            <a-icon type="copy" />
          </button>
        </a-tooltip>
        <!--  :class="{
                'plain-btn-disable': record.cameraPatrolPlanStatus === '2',
              }"
              :disabled="record.cameraPatrolPlanStatus === '2'" -->
        <a-tooltip placement="top">
          <template slot="title">
            <span>轮巡预监</span>
          </template>
          <button
            class="plain-btn pointer m-r-sm"
            @click="viewHandle(record)"
          >
            <a-icon type="desktop" /></button
        ></a-tooltip>
        <a-tooltip
          placement="top"
          v-if="record.cameraPatrolPlanStatus !== '1'"
        >
          <template slot="title">
            <span>删除</span>
          </template>
          <button
            class="plain-btn pointer"
            @click="delHandle(record)"
          >
            <a-icon type="rest" />
          </button>
        </a-tooltip>
      </template>
      <template slot="footer">
        <a-checkbox
          v-model="checkAllFlag"
          @change="checkAll"
        ></a-checkbox>
        <a-button
          type="primary"
          class="m-l-sm"
          @click="deleteAll"
          :disabled="selectedRowKeys.length <= 0"
          >批量删除</a-button
        >
      </template>
    </a-table>

    <!-- 全量修改弹窗 -->
    <turn-dialog
      @closeDialog="turnVisible = false"
      @refreshData="refreshDataHandle"
      :selectAllMode="selectAllMode"
      :visible="turnVisible"
      :isEdit="isEdit"
    ></turn-dialog>

    <!-- 查看结果弹窗 -->
    <turn-result-dialog
      :id="currentId"
      :visible="turnResultVisible"
      @close="turnResultVisible = false"
    />

    <!-- 局部修改弹窗 -->
    <parts-edit
      :show="isPartsEditShow"
      :propData="partsEditData"
      @update=";(isPartsEditShow = false), _getTurnList()"
      @close="isPartsEditShow = false"
    />
  </div>
</template>

<script>
import Back from '@/components/Back'
import TurnDialog from '@/components/TurnDialog'
import SearchBar from './components/SearchBar'
import TurnResultDialog from './components/TurnResultDialog'
import partsEdit from './components/PartsEdit'
import { mapState, mapActions, mapMutations } from 'vuex'
import { getStorage, setStorage } from '@/tools'
import ResizableTable from '@/components/mixins/resizeableTable'
export default {
  name: 'TurnPro',
  mixins: [ResizableTable],
  data() {
    let href = location.host
    let protocol = location.protocol
    let ph = `${protocol}//${href}`
    return {
      url: `${ph}/#/single-window`,
      selectedRowKeys: [],
      selectedRowsAll: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'cameraIndex',
          customRender: (text, record, index) => index + 1,
          width: 60
        },
        {
          title: '预案名称',
          dataIndex: 'cameraPatrolName',
          key: 'cameraPatrolName',
          ellipsis: true,
          width: 200
        },
        {
          title: '摄像机数量',
          dataIndex: 'cameraNumber',
          key: 'cameraNumber',
          ellipsis: true,
          width: 90
        },
        {
          title: '轮巡模式',
          dataIndex: 'turnMode',
          key: 'turnMode',
          ellipsis: true,
          width: 80
        },
        {
          title: '切换频率',
          dataIndex: 'cameraPatrolFrequencyInfo',
          key: 'cameraPatrolFrequencyInfo',
          ellipsis: true,
          width: 80
        },
        {
          title: '轮巡时间',
          dataIndex: 'turnDate',
          key: 'turnDate',
          width: 120,
          ellipsis: true
          // scopedSlots: { customRender: 'cameraPatrolStartTime' },
        },
        // {
        //   title: '清晰度',
        //   dataIndex: 'highDefinitionText',
        //   key: 'highDefinitionText',
        //   width: 80,
        //   ellipsis: true,
        //   // scopedSlots: { customRender: 'cameraPatrolStartTime' },
        // },
        {
          title: '描述',
          dataIndex: 'cameraPatrolDescribe',
          key: 'cameraPatrolDescribe',
          width: 200,
          ellipsis: true
          // scopedSlots: { customRender: 'cameraPatrolStartTime' },
        },
        {
          title: '创建人',
          dataIndex: 'userName',
          key: 'userName',
          ellipsis: true,
          width: 100
        },
        {
          title: '创建时间',
          dataIndex: 'gmtCreate',
          key: 'gmtCreate',
          ellipsis: true,
          width: 120
          // scopedSlots: { customRender: 'cameraStatus' },
        },
        {
          title: '预案状态',
          dataIndex: 'turnStatusText',
          key: 'turnStatusText',
          width: 90,
          ellipsis: true
          // scopedSlots: { customRender: 'cameraPatrolPlanStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          className: 'column-center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 10, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1
          this.paginationOpt.defaultPageSize = pageSize
          // this.resetSelect();
          this._getTurnList()
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current
          this.paginationOpt.defaultPageSize = size
          // this.selectedRowKeys = []; //fixsxy
          this.resetSelect()
          this._getTurnList()
        }
      },
      // cameraAllList: [],
      turnVisible: false,
      selectAllMode: 'all',
      isEdit: false,
      checkAllFlag: false,
      turnResultVisible: false,
      currentId: -1,
      scroll: { y: window.innerHeight - 300, x: '100%' },
      loading: false,

      isPartsEditShow: false, // 局部修改弹窗显隐
      partsEditData: {} // 局部修改弹窗props
    }
  },
  computed: {
    ...mapState({
      turnList: (state) => state.cameraPatrol.turnList,
      searchParams: (state) =>
        state.cameraPatrol.searchParams
    }),
    turnFormatList() {
      return this.turnList.map((item) => {
        item.turnMode = `${item.cameraPatrolFrame}画面 ${item.cameraPatrolFrameInfo}`
        // item.turnDate = `${item.cameraPatrolStartTime}~${item.cameraPatrolEndTime}`;
        item.turnDate = `${item.cameraPatrolStartTime}`
        item.highDefinitionText = `${
          item.highDefinition === 0 ? '标清' : '高清'
        }`
        item.turnStatusText =
          item.cameraPatrolPlanStatus === '0'
            ? '等待轮巡'
            : item.cameraPatrolPlanStatus === '1'
            ? '轮巡中'
            : '轮巡结束'
        return item
      })
    }
  },
  watch: {},
  components: {
    Back,
    SearchBar,
    TurnDialog,
    TurnResultDialog,
    partsEdit
  },
  mounted() {
    this._getTurnList()
    window.addEventListener(
      'onmessageWS',
      this.getsocketData
    )
  },
  methods: {
    ...mapMutations([
      'setEditTurnParams',
      'setSelectCameraList',
      'setCameraTab',
      'setSearchParams'
    ]),
    ...mapActions([
      'getCameraPatrolPlanList',
      'deleteCameraPatrolPlan',
      'playOrPausePlan'
    ]),
    //导出数据
    /*   exportDataHandle() {
      let data = {
        ...this.searchParams,
        ...{
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
        },
      };
      console.log('data ',data);
    }, */
    getsocketData(e) {
      // 创建接收消息函数
      console.log('haha', e)
      // const data = e && JSON.parse(e.detail.data);
      if (
        /*'patrol' in data*/ e.detail.data.includes(
          'patrol'
        )
      ) {
        this.paginationOpt.current = 1
        this.paginationOpt.defaultPageSize = 10
        this._getTurnList()
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      // console.error(this.selectedRowKeys);
      this.selectedRowsAll = selectedRows
      if (this.turnList.length === selectedRows.length) {
        this.checkAllFlag = true
      } else {
        this.checkAllFlag = false
      }
    },
    resetSelect() {
      this.selectedRowKeys = []
      this.selectedRowsAll = []
    },
    checkAll(e) {
      // console.log(`checked = ${e.target.checked}`);
      if (e.target.checked) {
        let len =
          this.paginationOpt.total <
          this.paginationOpt.defaultPageSize
            ? this.paginationOpt.total
            : this.paginationOpt.defaultPageSize
        let arr = new Array(len).fill(1)
        arr = arr.map((item, index) => index)
        this.selectedRowKeys = arr
        this.selectedRowsAll = this.turnFormatList
      } else {
        this.selectedRowKeys = []
        this.selectedRowsAll = []
      }
      this.checkAllFlag = e.target.checked
    },
    onSelectAllHandle(selected, selectedRows) {
      // console.error(selected, selectedRows, changeRows);
      this.checkAllFlag = selected
      this.selectedRowsAll = selectedRows
      let arr = new Array(selectedRows.length).fill(1)
      arr = arr.map((item, index) => index)
      this.selectedRowKeys = arr
    },
    _getTurnList() {
      /* let data = {
        cameraPatrolEndTime: '',
        cameraPatrolName: '',
        cameraPatrolPlanStatus: '',
        cameraPatrolStartTime: '',
        currPage: 1,
        pageSize: 10,
        userName: '',
      }; */
      let data = {
        ...this.searchParams,
        ...{
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize
        }
      }
      this.loading = true
      this.getCameraPatrolPlanList(data).then((data) => {
        if (data.code === 200) {
          this.loading = false
          this.paginationOpt.total = data.total
        }
      })
    },
    searchHandle() {
      this._getTurnList()
    },
    deleteAll() {
      if (!this.hasPermission(10921513)) {
        this.permissionWarning()
        return
      }

      let data = this.selectedRowsAll.map((item) =>
        String(item.id)
      )
      this.deleteCameraPatrolPlan(data).then((res) => {
        if (res.code === 200) {
          this.$message.success(
            `删除轮巡预案${data.length}条记录成功！`
          )
          this._getTurnList()
          this.resetSelect()
        }
      })
    },
    playHandle(record) {
      this.$confirm({
        title: '提示',
        content: '确定开始执行当前轮巡预案?',
        onOk: () => {
          this.playOrPause(1, record)
        },
        onCancel: () => {}
      })
    },
    pauseHandle(record) {
      this.$confirm({
        title: '提示',
        content: '确定停止当前轮巡预案执行?',
        onOk: () => {
          this.playOrPause(0, record)
        },
        onCancel: () => {}
      })
      // this.playOrPause(0, record);
    },
    playOrPause(flag, record) {
      let data = {
        flag,
        cameraPatrolPlanId: record.id
      }
      this.playOrPausePlan(data).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作轮巡预案成功')
          this._getTurnList()
        }
      })
    },
    editHandle(record) {
      if (!this.hasPermission(10921511)) {
        this.permissionWarning()
        return
      }

      if (record.cameraPatrolPlanStatus == 0) {
        this.turnVisible = true
        this.setEditTurnParams(record)
        this.selectAllMode =
          record.cameraPatrolFlag === 0
            ? 'group'
            : record.cameraPatrolFlag === 1
            ? 'collect'
            : 'all'
        // console.error(record, this.selectAllMode);
        this.$api.cameraPatrol
          .getPatrolCamera(record.id)
          .then((res) => {
            this.setCameraTab(this.selectAllMode)
            this.isEdit = true
            if (res.code === 200) {
              this.setSelectCameraList(res.data)
            } else {
              this.setSelectCameraList([])
              this.$message.error(
                '拉取轮巡预案关联摄像机列表有误！'
              )
            }
          })
        //暂时这么改
      } else {
        this.isPartsEditShow = true
        ;[
          'cameraPatrolName',
          'cameraPatrolDescribe',
          'id'
        ].forEach((key) => {
          this.partsEditData[key] = record[key]
        })
      }
    },
    refreshDataHandle() {
      this._getTurnList()
    },
    delHandle(record) {
      if (!this.hasPermission(10921512)) {
        this.permissionWarning()
        return
      }

      this.$confirm({
        title: '提示',
        content: '确定删除该条预案吗？',
        onOk: () => {
          this.deleteCameraPatrolPlan([
            String(record.id)
          ]).then((res) => {
            if (res.code === 200) {
              this.$message.success(
                '删除轮巡预案1条记录成功！'
              )
              this._getTurnList()
              this.resetSelect()
            }
          })
        },
        onCancel: () => {}
      })
      /* if (confirm('确定删除该条预案吗？')) {
        this.deleteCameraPatrolPlan([String(record.id)]).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除轮巡预案1条记录成功！');
            this._getTurnList();
            this.resetSelect();
          }
        });
      } */
    },
    viewResult(record) {
      console.log(record)
      this.currentId = record.id
      this.turnResultVisible = true
    },
    viewHandle(record) {
      let cameraPatrolFrame = record.cameraPatrolFrame
      let cameraPatrolFrameInfo =
        record.cameraPatrolFrameInfo

      let mode = ''
      // this.socket.socket.onopen();
      switch (cameraPatrolFrame) {
        case 1:
          mode = 'single'
          break
        case 4:
          mode = 'four'
          break
        case 6:
          mode = 'six'
          break
        case 9:
          mode = 'nine'
          break
        case 12:
          mode = 'twelve'
          break
        case 16:
          mode = 'sixteen'
          break
      }

      let { href } = this.$router.resolve({
        path: `/${mode}-window${cameraPatrolFrameInfo}`
      })
      // eslint-disable-next-line no-unreachable
      let userAccount = getStorage('userAccount')
      let cameraInfoStore = getStorage('patrolCamera')
      this.$api.cameraPatrol
        .getPatrolCamera(record.id)
        .then((res) => {
          if (res.code === 200) {
            record.cameraInfo = res.data
            let cameraInfoObj = {}
            for (let i in userAccount) {
              if (
                cameraInfoStore &&
                JSON.stringify(cameraInfoStore) != '{}' &&
                cameraInfoStore[userAccount[i]]
              ) {
                this.$message.error(
                  '同一个账户下轮巡预案只能拉流一次！'
                )
                return
              }
              if (userAccount[i] === record.userName) {
                cameraInfoObj[userAccount[i]] = record
              }
            }
            // let cameraInfos =
            window.open(
              `${href}?isPatrol=1&username=${encodeURIComponent(
                record.userName
              )}`,
              '_blank'
            )
            setStorage('patrolCamera', cameraInfoObj)
          }
        })
      // console.error(record);
    },
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    }
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.resizeWindow.bind(this));
    // 在需要的时候卸载监听事件，比如离开页面
    window.removeEventListener(
      'onmessageWS',
      this.getsocketData
    )
  },
  destroyed() {
    let searchParams = {
      cameraPatrolPlanStatus: '',
      userName: '',
      cameraPatrolStartTime: '',
      cameraPatrolEndTime: '',
      cameraPatrolName: ''
    }
    this.setSearchParams(searchParams)
  }
}
</script>

<style lang="less" scoped>
.ant-table-wrapper {
  ::v-deep .ant-table {
    .ant-table-content {
      .ant-table-footer {
        border: none;
        bottom: -64px;
        position: absolute;
      }
    }
  }
}
</style>
<style scoped>
.icon-g {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
